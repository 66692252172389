import consumer from './consumer'

consumer.subscriptions.create(
  {
    channel: 'ChatChannel',
    room: 'abc',
  },
  {
    received(data) {
      // just proof of concept
      console.log('~ received: ', data)
    },
  }
)
